import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Typography,
  Grid,
  Button,
  Menu,
  MenuItem,
  Box,
} from '@material-ui/core';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import FlashOnOutlinedIcon from '@material-ui/icons/FlashOnOutlined';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ListOutlinedIcon from '@material-ui/icons/ListOutlined';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import GroupIcon from '@material-ui/icons/Group';
import useStyles from './styles';
import HeaderSearch from '../../molecules/header-search/HeaderSearch';
import Header from '../header/Header';
import MobileDrawer, {
  HeaderElement,
} from '../../molecules/mobile-drawer/MobileDrawer';
import { useQuery } from '@apollo/client';
import { GetAllGroups } from '../../../shared/graphql/queries/groups';
import { IGroup } from '../../../shared-global/interfaces/models/group.interface';
import logo from '../../../img/DSicon.png';
import { BoxUserDetail } from '../../molecules/box-user-detail/BoxUserDetail';
import { title } from 'process';
import { Link } from 'react-router-dom';

const AuthHeader: React.FC = () => {
  const history = useHistory();
  // these are the constant values, that will not require changing from server
  const initialHeaderValues = [
    {
      name: 'Account',
      icon: <AccountCircleRoundedIcon />, //<ListOutlinedIcon />,
      hasMenu: true,
    },
    // {
    //   name: 'Groups', icon: <GroupIcon />, action: () => {
    //     history.push("/groups");
    //   }
    // },
    // { name: 'Settings', icon: <SettingsOutlinedIcon />, action: () => { } },
    // { name: 'Activity', icon: <FlashOnOutlinedIcon />, action: () => { } },
    // { name: 'Deals', icon: <AttachMoneyIcon />, action: () => { } },
    // {
    //   name: 'Insights', icon: <EmojiObjectsOutlinedIcon />, action: () => {
    //     history.push("/insights")
    //   }
    // },
  ];
  const classes = useStyles();
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const [openElements, setOpenElements] = useState<Record<string, boolean>>({});
  const [headerElements, setHeaderElements] =
    useState<HeaderElement[]>(initialHeaderValues); //initialHeaderValues
  const { data: dataGroups, loading: loadingGroups } = useQuery(GetAllGroups);

  const handleMenu =
    (name: string) => (event: React.MouseEvent<HTMLElement>) => {
      setOpenElements({
        ...openElements,
        [name]: true,
      });
      setAnchorElement(event.currentTarget);
    };

  const handleClose = (name: string) => (event: {}) => {
    setOpenElements({
      ...openElements,
      [name]: false,
    });
    setAnchorElement(null);
  };

  useEffect(() => {
    if (!loadingGroups && dataGroups) {
      const groups = dataGroups.getGroups as IGroup[];
      const accountIndex = headerElements.findIndex(
        (element) => element.name === 'Account',
      );
      const accountElement = headerElements[accountIndex];

      if (accountElement) {
        let submenuItems = [
          {
            name: 'My Profile',
            action: () => {
              history.push('/profile');
            },
          },
          {
            name: 'My Deals',
            action: () => {
              history.push('/mydeals');
            },
          },
          {
            name: 'Upload contacts',
            action: () => {
              history.push('/upload-contacts');
            },
          },
        ];
        // submenuItems = [...submenuItems, ...groups.map(group => ({
        //   name: group.name || "",
        //   action: () => {
        //     history.push(`/group/${group.id}`)
        //   }
        // }))];

        // submenuItems = [...submenuItems, {
        //   name: 'Log out',
        //   action: () => {
        //     localStorage.setItem("jwt", '');
        //     localStorage.setItem("refresh_token", '');
        //     history.replace("/")
        //     window.location.reload();
        //   }
        // }];
        accountElement.submenuItems = submenuItems;
        const newHeaderElements = headerElements;
        newHeaderElements[accountIndex] = accountElement;
        setHeaderElements(newHeaderElements);
      }
    }
  }, [dataGroups, loadingGroups, headerElements, setHeaderElements, history]);

  let content = headerElements.map((element) => {
    if (element.hasMenu) {
      return (
        <React.Fragment key={`auth-header-menu-${element.name}`}>
          <Button
            size="small"
            color="inherit"
            key={element.name}
            className={classes.buttonWithIcon}
            onClick={handleMenu(element.name)}
            aria-controls={`${element.name}-appbar`}
            aria-haspopup="true"
          >
            {/* {element.icon} */}
            {element.name}
          </Button>
          <Menu
            anchorEl={anchorElement}
            open={openElements[element.name]}
            id={`${element.name}-appbar`}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            onClose={handleClose(element.name)}
          >
            {element.submenuItems?.map((submenuElement) => (
              <MenuItem
                key={`auth-header-submenu-${submenuElement.name}`}
                onClick={() => {
                  submenuElement.action();
                  handleClose(element.name);
                }}
              >
                {submenuElement.name}
              </MenuItem>
            ))}
          </Menu>
        </React.Fragment>
      );
    } else {
      return (
        <Button
          size="small"
          color="inherit"
          key={element.name}
          className={classes.buttonWithIcon}
          onClick={element.action}
        >
          {element.icon}
          {element.name}
        </Button>
      );
    }
  });

  return (
    <Header
      leftSectionContent={
        <Box className={classes.containerUserDetail}>
          <BoxUserDetail />
        </Box>
      }
      mobileDrawerContent={<MobileDrawer headerElements={headerElements} />}
    >
      <Grid container item alignItems="center">
        <Grid container direction="row" className={classes.toolbarTitle}>
          <img src={logo} alt="Logo" className={classes.logoApp} />
          <Link
            to="/"
            style={{ textDecoration: 'none', color: '#fff', cursor: 'pointer' }}
          >
            <label className="company-name">Deal Seal</label>
          </Link>
        </Grid>
        <Grid
          container
          direction="row-reverse"
          className={classes.toolbarTitle}
        >
          {content}
        </Grid>
      </Grid>
      {/* <Grid container item 
        direction='row-reverse' 
        className={classes.subNavBar}
        >
        {
          content
        }
      </Grid> */}
    </Header>
  );
};

export default AuthHeader;
