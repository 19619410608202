import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { get } from 'lodash';
import { Button, Grid, Typography, Link } from '@material-ui/core';
import { SINGLE_UPLOAD } from '../../../graphql/queries/user';
import { MeQuery } from '../../../shared/graphql/queries/user';
import useStyles from './styles';
import AvatarImageWithUpload from '../../organisms/avatar-image-with-upload/AvatarImageWithUpload';
import { ISafeUser } from '../../../shared-global/interfaces/other/safe-user.interface';
import {
  CancelSubscription,
  GetCharges,
  ActivateSubscription,
} from '../../../shared/graphql/queries/account';
import { IChargesResponse } from '../../../shared-global/interfaces/graphql/charges-response-interface';
import PaymentChargesTable from '../../molecules/payment-charges-table/PaymentChargesTable';
import { useHistory } from 'react-router';
import { PAYMENT_STATUS } from '../../../shared-global/enums/account.enums';
import PopupAlertCancelSubscription from '../../molecules/popup-alert/PopupAlertCancelSubscription';

const baseUrl =
  process.env.REACT_APP_API_HOST ?? 'https://opptyrebeta.corbinbrooks.com';
const genericProfileUrl = `${process.env.PUBLIC_URL}generic-user.jpg`;

const buildImageUrl = (me: ISafeUser) => {
  if (me && me.profileImage) {
    return `${baseUrl}/users/${me.id}/${me.profileImage}?key=${Date.now()}`;
  } else {
    return genericProfileUrl;
  }
};

export const Profile: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { data: dataMe, refetch: refetchDataMe } = useQuery(MeQuery);

  const [cancelSubscription] = useMutation(CancelSubscription);
  const [activateSubscription] = useMutation(ActivateSubscription);
  const [uploadFile] = useMutation(SINGLE_UPLOAD);
  const [avatarKey, setAvatarKey] = useState<string>(
    Math.random().toString(36),
  );
  const { data: dataCharges, refetch: refetchCharges } = useQuery(GetCharges, {
    fetchPolicy: 'cache-and-network',
  });

  const uploadFileCallback = (file: File) => {
    uploadFile({
      variables: {
        file,
      },
    }).then((_) => {
      setAvatarKey(Math.random().toString(36));
    });
  };
  const goTosubscription = () => history.push('/subscription');

  const handleCancelSubscription = (userId: number) => {
    cancelSubscription({
      variables: {
        userId: userId,
      },
    })
      .then((res) => {
        if (res.data.cancelSubscription.status) {
          refetchDataMe();
          history.push('/profile');
        }
      })
      .catch((err) => {
        console.log('Error: ', err);
      });
  };

  const handleActivation = (userId: number) => {
    activateSubscription({
      variables: {
        userId: userId,
      },
    })
      .then((res) => {
        // console.log('data: ',res.data)
        if (res.data.activateSubscription.status) {
          refetchDataMe();
          history.push('/profile');
        }
      })
      .catch((err) => {
        console.log('Error: ', err);
      });
  };

  if (dataMe && dataMe.me && dataCharges) {
    const me = dataMe.me as ISafeUser;
    const charges = dataCharges.getCharges as IChargesResponse;
    let accountStatuToDisplay = '';
    switch (me?.account?.paymentStatus) {
      case PAYMENT_STATUS.PAID:
        accountStatuToDisplay = 'Active';
        break;
      case PAYMENT_STATUS.UNPAID:
        accountStatuToDisplay = PAYMENT_STATUS.UNPAID.toLowerCase();
        break;
      case PAYMENT_STATUS.INACTIVE:
        accountStatuToDisplay = 'Canceled';
        break;
      case PAYMENT_STATUS.TRIAL:
        accountStatuToDisplay = 'Trial';
        break;
      default:
        console.log('none');
    }

    return (
      <Grid container>
        <Grid item md={1} xs={false} />
        <Grid
          item
          md={10}
          xs={12}
          container
          direction="column"
          className={classes.gridContainer}
          spacing={2}
        >
          <Grid container direction="row" alignItems="center">
            <Grid item xs={12} sm={9}>
              <Typography variant="h3" className={classes.mainTitle}>
                {' '}
                My profile{' '}
              </Typography>
              <br />
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              direction="column-reverse"
              alignItems="baseline"
              className={classes.endPeriodDateLabel}
            >
              {me.account?.paymentStatus === PAYMENT_STATUS.INACTIVE ? (
                <Typography variant="caption">
                  {' '}
                  Suscription ends:{' '}
                  <label className={classes.boldText}>
                    {new Date(Number(me.endTrialPeriod)).toLocaleDateString()}
                  </label>
                </Typography>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={4} className={classes.avatarWrapper}>
              <AvatarImageWithUpload
                key={avatarKey}
                size={120}
                profileUrl={buildImageUrl(dataMe.me)}
                onUploadFile={uploadFileCallback}
              />
            </Grid>
            <Grid
              item
              xs={8}
              direction="column"
              className={classes.userNamesWrapper}
            >
              <Typography variant="inherit" className={classes.nameLabel}>
                {get(dataMe, 'me.firstName', '')}{' '}
                {get(dataMe, 'me.lastName', '')}
              </Typography>
              <Typography
                variant="body1"
                color="primary"
                className={classes.emailLabel}
              >
                {get(dataMe, 'me.email', '')}
              </Typography>
              <Typography
                variant="body1"
                color="primary"
                className={classes.emailLabel}
              >
                Account status:&nbsp;&nbsp;
                <label
                  className={
                    me?.account?.paymentStatus === PAYMENT_STATUS.PAID ||
                    me?.account?.paymentStatus === PAYMENT_STATUS.TRIAL
                      ? classes.activeLabel
                      : classes.noActiveLabel
                  }
                >
                  {accountStatuToDisplay}
                </label>
              </Typography>
            </Grid>
          </Grid>
          <br />

          <Grid container alignItems="flex-start" alignContent="flex-start">
            {me.account?.paymentStatus === PAYMENT_STATUS.INACTIVE &&
            new Date(Number(me.endTrialPeriod)) > new Date() ? (
              <Button
                variant="outlined"
                size="small"
                className={classes.btnGoSubcription}
                onClick={() => handleActivation(me.id)}
              >
                Activate account
              </Button>
            ) : (
              <></>
            )}
            {(me.account?.paymentStatus === PAYMENT_STATUS.INACTIVE &&
              new Date(Number(me.endTrialPeriod)) < new Date()) ||
            me.account?.paymentStatus === PAYMENT_STATUS.UNPAID ? (
              <Button
                variant="outlined"
                size="small"
                className={classes.btnGoSubcription}
                onClick={goTosubscription}
              >
                Pay subscription
              </Button>
            ) : (
              <></>
            )}

            {me.account?.paymentStatus === PAYMENT_STATUS.PAID ? (
              <PopupAlertCancelSubscription
                actionProp={() => handleCancelSubscription(me.id)}
              />
            ) : (
              <></>
            )}
          </Grid>
          <br />
          <Grid container alignItems="flex-start" alignContent="flex-start">
            <PaymentChargesTable chargesResponse={charges} />
          </Grid>
        </Grid>
        <Grid item md={1} xs={false} />
      </Grid>
    );
  }

  return null;
};
